<template>
  <ion-page>
    <div class="gallery-controls">
      <ion-button @click="zoomOut()" fill="clear">
        <ion-icon slot="icon-only" :icon="remove"></ion-icon>
      </ion-button>
      <ion-button @click="zoomIn()" fill="clear">
        <ion-icon slot="icon-only" :icon="add"></ion-icon>
      </ion-button>
      <ion-button @click="closeModal()" fill="clear">
        <ion-icon slot="icon-only" :icon="close"></ion-icon>
      </ion-button>
    </div>
    <div>
      <ion-slides ref="slidesRef" class="gallery-slides" v-if="media" :options="slideOpts">
        <ion-slide v-for="(media, mediaIndex) in media" :key="mediaIndex">
          <img class="swiper-zoom-target" v-if="media.type === 'image'" :src="media.blobURL" crossorigin="anonymous"/>
          <video class="swiper-zoom-target" v-else-if="media.type === 'video'" controls playsinline :poster='(media.thumbnail) ? media.thumbnail : undefined' crossorigin="anonymous">
              <source :src="media.blobURL" :type="media.mime">
          </video>
        </ion-slide>
      </ion-slides>
    </div>
  </ion-page>
</template>

<script>
import { IonPage, IonSlides, IonSlide, IonButton, IonIcon, modalController } from '@ionic/vue';
import { computed, defineComponent, onMounted, ref } from 'vue';

import { close, add, remove } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

const Gallery = defineComponent({
  name: 'Gallery',
  components: { IonPage, IonSlides, IonSlide, IonButton, IonIcon },
  props: {
    'media': Array, //Expects array of objects with type, mime and blobURL set 
    'selectedIndex': {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const i18n = useI18n();

    const slidesRef = ref(null);

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        centeredSlides: true,
        zoom: {
          maxRatio: 5
        },
        initialSlide: props.selectedIndex,
        init: false
      };
    });

    const closeModal = function(){
      modalController.dismiss();
    }

    const zoomOut = async function(){
      let swiper = await slidesRef.value.$el.getSwiper();
      if (swiper && swiper.zoom) {
        swiper.zoom.out();
      }
    }

    const zoomIn = async function(){
      let swiper = await slidesRef.value.$el.getSwiper();
      if (swiper && swiper.zoom) {
        swiper.zoom.in();
      }
    }

    onMounted(() => {
      //Workaround to fix issue, where the interaction does not work properly when buttons are added. If we wait a bit, the initialization can happen properly
      setTimeout(() => slidesRef.value.$el.getSwiper().then((swiper) => {
        if (swiper) {
          swiper.init();
        }
      }), 500);
    });

    return { i18n, slidesRef, slideOpts, closeModal, zoomOut, zoomIn, close, add, remove }
  }
});

export async function openGallery(component, media, selectedIndex){
  if (component != null && media != null) {
    const modal = await modalController
      .create({
        component,
        cssClass: 'media-gallery',
        componentProps: {
          media,
          selectedIndex
        },
      })
    return modal.present();
  }
}

export default Gallery;
</script>

<style>
.media-gallery {
  --background: transparent;
  --backdrop-opacity: 0.8!important;
  --height: 80vh;
  --width: 100vw;
  --min-height: 0px;
  --max-height: 80vh;
  --max-width: 100vw;
  padding-bottom: 5vh;
}

.gallery-slides {
  height: 70vh;
  margin: 0px;
}

.gallery-controls {
  width: 100%;
  text-align: end;
}

.gallery-controls ion-button{
  --color: white;
  --ripple-color: white;
}

.swiper-zoom-target {
  max-height: 70vh;
  max-width: 100vw;
}
</style>
