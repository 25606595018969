<template id="create-horse-popup">
  <ion-alert
    :is-open="isOpen"
    :header="i18n.$t('horse.create.title')"
    :message="i18n.$t('horse.create.message')"
    :buttons="buttons"
    :inputs="inputs"
    @didDismiss="isOpen = false"
  >
  </ion-alert> <!-- TODO Keep in mind max length of input based on input length in database in whole app -->
</template>



<script>

import { IonAlert } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  name: 'CreateHorsePopup',
  components: { IonAlert },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const isOpen = ref(false);

    const buttons = ref(null)

    const inputs = [ 
      {
        name: 'nameInput',
        id: 'create-horse-name-input',
        placeholder: i18n.$t('horse.create.name_placeholder')
      }
    ]

    const show = function(){
      return new Promise((resolve, reject) => {
        buttons.value = [
          {
            text: i18n.$t('default_interaction.cancel'),
            role: 'cancel',
            handler: () => {              
              reject();
            },
          },
          {
            text: i18n.$t('horse.create.okay'),
            cssClass: 'create-okay',
            handler: (alertData) => {
              
              if (alertData.nameInput.length > 0){
                store.dispatch('horses/createNewHorse', { name: alertData.nameInput })
                .then((newHorse) => {
                  resolve(newHorse.id);
                })
                .catch((error) => {
                  console.log(error.data); //TODO Show error popup
                  reject(error);
                })
                
                

                return true;
              } else {
                //Show missing name by flashing the input
                document.getElementById('create-horse-name-input').classList.remove('flash-input-short');
                document.getElementById('create-horse-name-input').offsetHeight; //Reflow to restart animation
                document.getElementById('create-horse-name-input').classList.add('flash-input-short');

                return false;
              }
              
            },
          }
        ]

        isOpen.value = true;
      });
    }

    return { i18n, buttons, inputs, isOpen, show }
  }
});
</script>

<style>
.create-okay {
  color: var(--ion-color-success)!important;
}

#create-horse-name-input {
  animation: none;
  animation-play-state: paused;
}

#create-horse-name-input.flash-input-short {
  animation: blink-danger-short 0.5s ease-in-out 2;
  animation-play-state: running;
}

@keyframes blink-danger-short {
  50% {
    border-color: var(--ion-color-danger);
  }
}
</style>
