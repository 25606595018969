<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="reportTime" :titleIcon="(isArchived) ? history : undefined " />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item lines="none" v-if="!loading" id="report-title">
        <ion-label>{{ localizedReportTypeName }}</ion-label>
        <ion-button 
          v-if="isReportBeingUploaded"
          shape="round"
          fill="clear"
          class="upload-progress-button"
          :color="getUploadStatusDesign(currentUploadStatus).shortColor"
          @click.stop="showUploadStatus()"> <!--TODO Maybe add green pencil icon to reports that are being updated, also in upload overview, and also maybe on already updated ones -->
          <CircularProgress
            class="upload-progress"
            :style="'--color: var(' + getUploadStatusDesign(currentUploadStatus).color + ');'"
            :progress="(currentUploadStatus.progress >= 0) ? currentUploadStatus.progress : 0">
            <ion-icon :icon="getUploadStatusDesign(currentUploadStatus).icon"></ion-icon>
          </CircularProgress>
        </ion-button> <!--TODO Was passiert mit Bericht, Status und Dateien, sobald der Bericht hochgeladen ist, während er offen ist? -->
      </ion-item>
      <div class="loading-container" v-if="loading"> <!-- TODO Test loading spinner visualization, position and behaviour on error -->
        <ion-spinner></ion-spinner>
      </div>
      <ion-list v-else>
        <ion-item>
          <ion-label>
            <h2>{{ i18n.$t('report.associated_horse') }}</h2>
            <h3>{{ (selectedHorse) ? selectedHorse.personal_horse_info.name : i18n.$t('report.view.no_horse') }}</h3>
          </ion-label>
        </ion-item>
        <CreateHorsePopup ref="createHorsePopup"></CreateHorsePopup>

        <ion-item v-if="report.location">
          <ion-label>
            <h2>{{ i18n.$t('report.location.name') }}</h2>
            <h3>{{ i18n.$t(`report.location.${report.location}`) }}</h3>
          </ion-label>
        </ion-item>

        <ion-item v-if="report.control_examination !== undefined">
          <ion-label>
            <h2>{{ i18n.$t('report.control_examination') }}</h2>
            <h3>{{ i18n.$t('default_interaction.' + ((report.control_examination) ? 'yes' : 'no')) }}</h3>
          </ion-label>
        </ion-item>
      
        <div v-for="(category, categoryIndex) in reportTypeDefinition" :key="categoryIndex" >
          <div v-if="category.name in report.fields && report.fields[category.name].type === 'category'">
            <ion-list-header v-if="category.name !== 'uncategorized'">
              <h3>{{ getLocalizedReportString(category.name) }}</h3>
            </ion-list-header>

            <div v-for="(item, itemIndex) in category.items" :key="itemIndex">
              <ViewReportEntryItem v-if="item.name in report.fields[category.name]"
                :display_name="getLocalizedReportString(item.name)"
                :type="report.fields[category.name][item.name].type"
                :unit="getLocalizedReportString(item.unit)"
                :value="getLocalizedReportValue(report.fields[category.name][item.name].value, report.fields[category.name][item.name].type)"
                :files="report.fields[category.name][item.name].files || report.fields[category.name][item.name].file" > 
              </ViewReportEntryItem>
            </div>

            <ion-item-group v-for="(subCategory, subCategoryIndex) in category.sub_categories" :key="subCategoryIndex"> <!-- TODO Better style titles for categories and subcategories to see what is a category and what is not -->
              <div v-if="subCategory.name in report.fields[category.name] && report.fields[category.name][subCategory.name].type === 'category'">
                <ion-item-divider>
                  <ion-label>{{ getLocalizedReportString(subCategory.name) }}</ion-label>
                </ion-item-divider>

                <div v-for="(item, itemIndex) in subCategory.items" :key="itemIndex">
                  <ViewReportEntryItem v-if="item.name in report.fields[category.name][subCategory.name]"
                    :display_name="getLocalizedReportString(item.name)"
                    :type="report.fields[category.name][subCategory.name][item.name].type"
                    :unit="getLocalizedReportString(item.unit)"
                    :value="getLocalizedReportValue(report.fields[category.name][subCategory.name][item.name].value, report.fields[category.name][subCategory.name][item.name].type)"
                    :files="report.fields[category.name][subCategory.name][item.name].files || report.fields[category.name][subCategory.name][item.name].file" >
                  </ViewReportEntryItem>
                </div>
              </div>
            </ion-item-group>

            <!-- Additional value for fields we did not think of -->
            <div v-if="'additional_comments' in report.fields[category.name]" >
              <ViewReportEntryItem
                :display_name="i18n.$t('report.create.additional_comments' + ((category.name === 'uncategorized') ? '_report' : ''))"
                :type="report.fields[category.name]['additional_comments'].type"
                :value="getLocalizedReportValue(report.fields[category.name]['additional_comments'].value, report.fields[category.name]['additional_comments'].type)" > 
              </ViewReportEntryItem>
            </div>
          </div>
        </div>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>
      <ion-fab :key="id" v-if="report != null" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="primary">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          
          <ion-fab-button v-if="relatedReports != null" color="primary" @click="showRelatedReportList()">
            <ion-icon :icon="history"></ion-icon>
          </ion-fab-button>
          <ion-fab-button v-if="report != null && !isArchived && !isReportBeingUploaded" color="success" @click="requestUpdate()"> <!-- Only show edit, if the report has not yet been edited! -->
            <ion-icon :icon="pencilOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button color="danger" :disabled="currentUploadStatus != null && currentUploadStatus.finishedID != null" @click="requestDelete()">
            <ion-icon :icon="trashOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-fab :key="id" vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button color="tertiary" v-if="!loading">
          <ion-icon :icon="shareSocialOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button color="tertiary" @click="exportReport()">
            <ion-icon :icon="documentTextOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>

      <ion-modal
        :is-open="relatedReportDialogOpen"
        @didDismiss="relatedReportDialogOpen = false">
        <ion-page>
          <ion-header translucent>
            <ion-toolbar>
              <ion-title>
                {{ i18n.$t('report.view.related-reports.title') }}
              </ion-title>
              <ion-buttons slot="end">
                <ion-button @click="relatedReportDialogOpen = false">{{ i18n.$t('default_interaction.close') }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

          <ion-content>
            <ion-list lines="full">
              <ion-item 
                v-for="(relatedReport, index) in relatedReports" :key="index"
                @click="(relatedReport.id != id) ? openRelatedReport(relatedReport.id) : null"
                :button="relatedReport.id != id"
                :detail="relatedReport.id != id"
                :class="relatedReport.id != id ? '' : 'selected-related-report'">
                  <span slot="start" class="related-report-status">
                    <span class="status-icon">
                      <ion-icon v-if="relatedReport.id.startsWith && relatedReport.id.startsWith('U')" :icon="cloudUploadOutline" color="warning" ></ion-icon>
                      <ion-icon v-else-if="index > 0" :icon="archiveOutline" color="secondary" ></ion-icon>
                      <ion-icon v-else :icon="checkmarkCircleOutline" color="success"></ion-icon>
                    </span>
                    <ion-label v-if="index == (relatedReports.length - 1)" color="secondary">{{ i18n.$t('report.view.related-reports.original') }}: </ion-label>
                    <ion-label v-else-if="index > 0" color="secondary">{{ i18n.$t('report.view.related-reports.version') }} {{ relatedReports.length - index - 1 }}: </ion-label>
                    <ion-label v-else :color="(relatedReport.id.startsWith && relatedReport.id.startsWith('U')) ? 'warning' : 'success'">{{ i18n.$t('report.view.related-reports.latest') }}: </ion-label>
                  </span>
                  <span>{{ (relatedReport.id.startsWith && relatedReport.id.startsWith('U')) ? i18n.$t('report.view.related-reports.uploading') : timeToString(relatedReport.created_at) }}</span>
              </ion-item>
            </ion-list>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonSpinner, IonList, IonListHeader, IonItemGroup, IonItem, IonLabel, IonItemDivider, IonFab, IonFabList, IonFabButton, IonIcon, IonModal, IonToolbar, IonButtons, IonButton, IonTitle, alertController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { pencilOutline, shareSocialOutline, documentTextOutline, ellipsisVertical, archiveOutline, checkmarkCircleOutline, trashOutline, cloudUploadOutline } from 'ionicons/icons';
import history from '@/assets/icons/history.svg';

import MainToolbar from '@/components/MainToolbar.vue';
import ViewReportEntryItem from '@/components/ViewReportEntryItem.vue'

import CreateHorsePopup from '@/components/CreateHorsePopup.vue';
import CircularProgress from '@/components/CircularProgress.vue';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { apiErrorToast } from '@/utils/error';

import { openReportExportModalSingleReport, default as modalComponent } from '@/components/ReportExportModal.vue';
import { openUploadStatusModal, default as uploadModalComponent } from '@/components/UploadStatusModal.vue';

export default defineComponent({
  name: 'ViewReport',
  components: {
    IonPage, IonHeader, IonContent, IonSpinner, IonList, IonListHeader, IonItemGroup, IonItem, IonLabel, IonItemDivider, IonFab, IonFabList, IonFabButton, IonIcon, IonModal, IonToolbar, IonButtons, IonButton, IonTitle, MainToolbar, ViewReportEntryItem, CreateHorsePopup, CircularProgress
  },
  props: {
    id: String
  },
  setup(props){
    const store = useStore();

    const i18n = useI18n();

    const router = useRouter();

    const { dayjs, isReady, dayjsLocale, timezone } = useDayjs();

    const loading = ref(true);

    const report = ref(null);

    const relatedReports = computed(() => {
      if (report.value != null && report.value.id != null){
        return store.getters['reports/getRelatedReportList'](report.value.id);
      } else if (report.value != null && isReportBeingUploaded.value && currentUploadStatus.value != null && currentUploadStatus.value.id != null) {
        return store.getters['reports/getRelatedReportList'](currentUploadStatus.value.id); //Get the id from the report it will update and get all related reports
      }
      return null;
    });

    const hasRelatedReports = computed(() => {
      return (relatedReports.value != null && relatedReports.value.length >= 1);
    })

    const isArchived = computed(() => {
      //Archived versions are those that are not on top of the chain, if the chain exists
      return (!isReportBeingUploaded.value && hasRelatedReports.value && relatedReports.value[0].id != report.value.id);
    });

    const relatedReportDialogOpen = ref(false);

    const reportType = ref(null);

    const availableHorses = computed(() => store.getters['horses/getHorses']);

    const selectHorseInput = ref(null);

    const createHorsePopup = ref(null);

    const selectedHorseId = ref(null);

    const isReportBeingUploaded = computed(() => {
      return (props.id != null && props.id.startsWith('U'));
    });

    const currentUploadStatus = computed(() => {
      if (isReportBeingUploaded.value) {
        let status = store.getters['reports/getReportUploadStatus'][props.id.split('U')[1]];
        if (status != null) return status;
        else return { progress: 100 }; //Mark as completed, if it is an uploaded report, but it is not in the uploadStatus anymore
      } else {
        return {};
      }
    });

    const selectedHorse = computed(() => {
      if (selectedHorseId.value && availableHorses.value && availableHorses.value[selectedHorseId.value]) {
        return availableHorses.value[selectedHorseId.value];
      } else {
        return null;
      }
    });

    const reportTime = computed(() => {
      if (report.value && report.value.timestamp) {
        if (isReady.value) {
          return dayjs.utc(report.value.timestamp).locale(dayjsLocale.value).tz(timezone).format('LLL');
        } else {
          return dayjs.utc(report.value.timestamp).tz(timezone).format('LLL');
        }
      } else {
        return i18n.$t('report.view.title');
      }
    });

    const reportTypeDefinition = computed(() => {
      if (reportType.value) {
        return reportType.value.definition; //TODO Maybe only fetch if not already loaded and exclude normally when fetching all types, only return on single type request
      } else {
        return null;
      }
    });
    
    const localizedReportTypeName = computed(() => {
      if (reportType.value) {
        return reportType.value.descriptor; //TODO Use the translated name here
      } else {
        return null;
      }
    });

    const timeToString = function(timestamp){
      if (timestamp == null) {
        return null;
      }
      
      if (isReady.value) {
        return dayjs.utc(timestamp).locale(dayjsLocale.value).tz(timezone).format('LLL');
      } else {
        return dayjs.utc(timestamp).tz(timezone).format('LLL');
      }
    }

    const getLocalizedReportString = function(text) {
      return text; //TODO Use translation relation to translate each string here.
    }

    const getLocalizedReportValue = function(value, type) {
      if (type === 'bool') {
        return i18n.$t('default_interaction.' + ((value) ? 'yes' : 'no'));
      } else {
        return getLocalizedReportString(value); //TODO Use translation relation to translate each string here, if it matches (One of the available values for selection)
      }
    }

    const getLocalizedReportStringArray = function(textArray) {
      if (Array.isArray(textArray)){
        return textArray.map((text) => {
          return {
            value: text,
            display: getLocalizedReportString(text)
          }
        });
      } else {
        return null;
      }
    }

    const fetchReport = function(id) {
      let reportPromise;
      if (isReportBeingUploaded.value) reportPromise = store.dispatch('reports/fetchUploadingReport', id.split('U')[1]);
      else reportPromise = store.dispatch('reports/fetchReport', id);
      reportPromise
        .then((loadedReport) => {
          report.value = loadedReport;
          if (loadedReport.horse) {
            selectedHorseId.value = parseInt(loadedReport.horse, 10);
          } else {
            selectedHorseId.value = null;
          }

          return loadedReport;
        })
        .then((loadedReport) => {
          if (loadedReport.type) {
            return store.dispatch('reports/fetchReportType', loadedReport.type);
          } else {
            return Promise.reject();
          }
        })
        .then((loadedReportType) => {
          reportType.value = loadedReportType;
        })
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true)
            .then(() => router.go(-1));
        });
    }

    watch(currentUploadStatus, (newUploadStatus) => {
      if (newUploadStatus != null && newUploadStatus.finishedID != null) router.replace({ name: 'view-report', params: { id: newUploadStatus.finishedID } }); //TODO Maybe ask before forwarding with a toast. Keep in mind, that it might be triggered multiple times. Only ask, when this attribute changes here. The other ones forward immediately and don't require to ask the user!
    }, { deep: true });

    watch(() => props.id, (newId) => {
      if (currentUploadStatus.value != null && currentUploadStatus.value.finishedID != null) {
        router.replace({ name: 'view-report', params: { id: currentUploadStatus.value.finishedID } });
      } else {
        loading.value = true;
        fetchReport(newId);
      }
    });

    onMounted(() => {
      if (props.id) {
        if (currentUploadStatus.value != null && currentUploadStatus.value.finishedID != null) {
          router.replace({ name: 'view-report', params: { id: currentUploadStatus.value.finishedID } });
        } else {
          loading.value = true;
          fetchReport(props.id);
        }
      }
    });

    const requestUpdate = function(){
      router.push({ name: 'update-report', params: { id: props.id } });
    }

    const deleteReport = function(id){
      let deletePromise;
      if (isReportBeingUploaded.value) deletePromise = store.dispatch('reports/removeUploadFromQueue', id.split('U')[1]).then(() => store.commit('reports/removeReportUploadStatus', id.split('U')[1]));
      else deletePromise = store.dispatch('reports/deleteReport', id)
      deletePromise
        .then(() => {
            toastController.create(
              {
                message: i18n.$t('report.view.deleted'),
                position: 'top',
                duration: 5000,
                color: 'success',
                buttons: [
                  {
                    text: i18n.$t('default_interaction.close'),
                    role: 'cancel'
                  }
                ]
              }
            ).then((toast) => {
              toast.present();
              router.replace(`/health/tracking`);
            });
          })
        .catch((error) => {
          apiErrorToast(i18n, error, true);
        });
    }

    const requestDelete = async function(){
      if (report.value != null && (report.value.id != null || isReportBeingUploaded.value)){
        let timestamp = (isReportBeingUploaded.value ? report.value.timestamp : report.value.created_at);
        const alert = await alertController
        .create({
          cssClass: 'delete-confirmation-alert',
          header: i18n.$t('report.view.delete-confirmation.title' + ((isArchived.value) ? '-version' : (isReportBeingUploaded.value ? '-upload' : ''))),
          message: i18n.$t('report.view.delete-confirmation.message.before' + ((isArchived.value) ? '-version' : (isReportBeingUploaded.value ? '-upload' : ''))) +
            '<b>' + timeToString(timestamp) + '</b>' +
            i18n.$t('report.view.delete-confirmation.message.after') +
            ((!isReportBeingUploaded.value && hasRelatedReports.value) ? i18n.$t('report.view.delete-confirmation.message.delete-versions') : ''),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('report.view.delete-confirmation.delete'),
              cssClass: 'delete-confirmation-okay',
              handler: () => {
                deleteReport((isReportBeingUploaded.value ? props.id : report.value.id));
              },
            },
          ],
        });
        return alert.present();
      }
    }

    //TODO Make report an object and move this function to report object to better convert multiple reports
    const localizeAndPopulateReport = function(report){ //TODO Basically the same as in the template above, maybe reuse this code above
      let newReport = {
        id: report.id,
        title: localizedReportTypeName.value,
        time: reportTime.value,
        horse: availableHorses.value[selectedHorseId.value],
        location: i18n.$t(`report.location.${report.location}`),
        control_examination: report.control_examination,
        files: report.files,
        fields: []
      }

      if (newReport.horse && newReport.horse.personal_horse_info){
        newReport.horse = newReport.horse.personal_horse_info.name;
      } else {
        newReport.horse = i18n.$t('report.view.no_horse');
      }

      for (let category of reportTypeDefinition.value) {
        if (category.name in report.fields && report.fields[category.name].type === 'category'){
          let tmpCategory = {
            name: category.name,
            display_name: ((category.name !== 'uncategorized') ? getLocalizedReportString(category.name) : ''),
            items: []
          }

          for (let item of category.items){
            if (item.name in report.fields[category.name]){
              tmpCategory.items.push({
                name: item.name,
                display_name: getLocalizedReportString(item.name),
                type: report.fields[category.name][item.name].type,
                unit: getLocalizedReportString(item.unit),
                value: getLocalizedReportValue(report.fields[category.name][item.name].value, report.fields[category.name][item.name].type),
                files: report.fields[category.name][item.name].files || report.fields[category.name][item.name].file
              });
            }
          }

          if (category.sub_categories){
            tmpCategory.sub_categories = [];
            for (let subCategory of category.sub_categories) {
              let tmpSubCategory = {
                name: subCategory.name,
                display_name: getLocalizedReportString(subCategory.name),
                items: []
              }
              if (subCategory.name in report.fields[category.name] && report.fields[category.name][subCategory.name].type === 'category'){
                for (let item of subCategory.items) {
                  if (item.name in report.fields[category.name][subCategory.name]) {
                    tmpSubCategory.items.push({
                      name: item.name,
                      display_name: getLocalizedReportString(item.name),
                      type: report.fields[category.name][subCategory.name][item.name].type,
                      unit: getLocalizedReportString(item.unit),
                      value: getLocalizedReportValue(report.fields[category.name][subCategory.name][item.name].value, report.fields[category.name][subCategory.name][item.name].type),
                      files: report.fields[category.name][subCategory.name][item.name].files || report.fields[category.name][subCategory.name][item.name].file
                    });
                  }
                }
                tmpCategory.sub_categories.push(tmpSubCategory);
              }
            }
          }

          //Additional value for fields we did not think of
          if ('additional_comments' in report.fields[category.name]){
            tmpCategory.items.push({
              name: 'additional_comments',
              display_name: i18n.$t('report.create.additional_comments' + ((category.name === 'uncategorized') ? '_report' : '')),
              type: report.fields[category.name]['additional_comments'].type,
              value: getLocalizedReportValue(report.fields[category.name]['additional_comments'].value, report.fields[category.name]['additional_comments'].type)
            });
          }

          newReport.fields.push(tmpCategory);
        }
      }

      return newReport;
    }

    const exportReport = function(){
      openReportExportModalSingleReport(modalComponent, localizeAndPopulateReport(report.value)); //TODO Maybe date and datetime have to be converted and fix issue with UNKNOWN images when an unsupported type is trying to be added.
    }

    const showRelatedReportList = function(){
      relatedReportDialogOpen.value = true;
    }

    const openRelatedReport = function(id){
      router.replace({ name: 'view-report', params: { id: id } });
      relatedReportDialogOpen.value = false;
    }

    const getUploadStatusDesign = function(status){
      return store.getters['reports/getUploadStatusDesign'](status);
    }

    const showUploadStatus = function(){
      openUploadStatusModal(uploadModalComponent);
    }

    return {
      store,
      i18n,
      loading,
      report,
      relatedReports,
      isArchived,
      isReportBeingUploaded,
      currentUploadStatus,
      getUploadStatusDesign,
      showUploadStatus,
      showRelatedReportList,
      relatedReportDialogOpen,
      openRelatedReport,
      availableHorses,
      selectHorseInput,
      createHorsePopup,
      selectedHorse,
      reportTime,
      reportTypeDefinition,
      localizedReportTypeName,
      timeToString,
      getLocalizedReportString,
      getLocalizedReportValue,
      getLocalizedReportStringArray,
      requestUpdate,
      requestDelete,
      exportReport,
      pencilOutline,
      shareSocialOutline,
      documentTextOutline,
      history,
      archiveOutline,
      checkmarkCircleOutline,
      ellipsisVertical,
      trashOutline,
      cloudUploadOutline
    }
  }
});
</script>

<style>
.delete-confirmation-okay, .overwrite-confirmation-okay {
  color: var(--ion-color-danger)!important;
}

.select-horse .new-button, .select-horse .new-button:hover {
  color: var(--ion-color-success);
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

#over-scroll {
  width: 100%;
  height: 100px;
}

#report-title {
  --background: var(--ion-color-tertiary-shade);
}

#report-title ion-label {
  text-align: center;
  padding: 5px;
  font-size: 1.2em;
  color: var(--ion-color-tertiary-contrast);
  background-color: var(--ion-color-tertiary-shade);
}

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related-report-status {
  width: 110px;
}

.related-report-status .status-icon {
  height: 100%;
  vertical-align: middle;
  display: inline;
}

.related-report-status ion-icon {
  margin-right: 10px;
}

.related-report-status ion-label {
  display: inline;
}

.selected-related-report {
  font-weight: bold;
}

.upload-progress-button {
  height: 2.5em;
  width: 2.5em;
  --padding-bottom: 0px;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  font-size: inherit;
  background: var(--ion-item-background, var(--ion-background-color, #fff));
  border-radius: 50%;
}

ion-fab-button.fab-button-disabled {
  opacity: 0.5;
}
</style>