import { jsPDF } from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
import QRCodeStyling from 'qr-code-styling';

applyPlugin(jsPDF);

import '@/fonts/pdf/Oxygen_bold';
import '@/fonts/pdf/Oxygen_regular';
import '@/fonts/pdf/MPLUS1p_bold';
import '@/fonts/pdf/MPLUS1p_regular';

const MARGIN = 10;

const FIRST_LINE_X = MARGIN;

const IMAGE_HEIGHT = 40;

function textAndDimension(doc, text, x, y){
  doc.text(text, x, y);
  return doc.getTextDimensions(text);
}

function booleanIcon(doc, value, x, y){ // eslint-disable-line no-unused-vars
  let currentFont = doc.getFont().fontName;

  doc.setFont('MPLUS1p');
  doc.text(value ? '\u2714' : '\u2716', x, y);
  doc.setFont(currentFont);
}

function displayCategory(doc, category, titleFontWeight, titleIndent, nextY, i18n){ 
  let currentFontStyle = doc.getFont().fontStyle;

  let initialY = nextY;

  if (category.display_name && category.display_name.length > 0){
    doc.setFont('Oxygen', titleFontWeight);
    doc.text(category.display_name, FIRST_LINE_X + titleIndent, nextY);
    doc.setFont('Oxygen', currentFontStyle);

    nextY += 2;
  }

  let tableData = [];

  let items = [];
  //TODO Add index inidicator in a lower corner of mulitple media files to identify them corresponding to the downloaded files
  for (let item of category.items){ //TODO Add sound indicator for sound files
    let newTableEntry = { //TODO Add little video indicator for video files
      name: item.display_name, //TODO Add placeholder for images and videos, if no thumbnail is loaded
      value: item.value,
      unit: item.unit
    };

    items.push(item);

    tableData.push(newTableEntry);
  }

  const lineHeight = 6;

  if (tableData.length > 0){
    let currentTextColor = doc.getTextColor();
    doc.setTextColor('black')
    doc.autoTable({
      startY: nextY,
      body: tableData,
      showHead: 'never',
      styles: { textColor: 10 },
      bodyStyles: {fillColor: [ 237, 250, 255 ]},
      alternateRowStyles: { fillColor: [214, 241, 255] },
      rowPageBreak: 'avoid',
      columnStyles: {
        value: { halign: 'right' },
        unit: { textColor: [159, 89, 182], fontStyle: 'bold', cellWidth: 30 }
      },
      didParseCell: function(data) {
        //Only calculate once per row!
        if (data.column.index === 0){
          let item = items[data.row.index];

          let files;

          if (item.files) {
            //Stores indizes of the files as rows to determine how many files fit in a single row and when they need to be split to the next row
            item.filePrintIndizes = [{width: 0, indizes: []}];
      
            let tableWidth = doc.internal.pageSize.getWidth() - data.settings.margin.left - data.settings.margin.right;

            let maxCellHeight = doc.internal.pageSize.getHeight() - data.settings.margin.top - data.settings.margin.bottom - 1; //Prevent cells from being longer than one page and being broken up

            if (Array.isArray(item.files)) { //Multiple files
              files = item.files;
            } else {
              files = [item.files];
            }

            for (let fileIndex = 0; fileIndex < files.length; fileIndex++){
              let file = files[fileIndex];
              let thumbnail;

              if (file.mime.includes('image')) {
                thumbnail = file.blobURL;
              } else if (file.thumbnail) {
                thumbnail = file.thumbnail;
              }

              if (thumbnail) {

                let imageProps = doc.getImageProperties(thumbnail);

                let resizeFactor =  IMAGE_HEIGHT / imageProps.height;

                file.printSize = {
                  width: imageProps.width * resizeFactor,
                  height: IMAGE_HEIGHT
                }

                let imageWidthWithPadding = data.cell.padding('horizontal') + file.printSize.width;

                let newRowWidth = item.filePrintIndizes[item.filePrintIndizes.length -1].width + imageWidthWithPadding;
                if (newRowWidth > tableWidth) {
                  item.filePrintIndizes.push({width: 0, indizes: [
                    fileIndex
                  ]});
                } else {
                  item.filePrintIndizes[item.filePrintIndizes.length -1].indizes.push(fileIndex);
                }

                item.filePrintIndizes[item.filePrintIndizes.length -1].width += imageWidthWithPadding;
              }
            }

            data.cell.styles.minCellHeight = Math.min(
              lineHeight + ((data.cell.padding('vertical') + IMAGE_HEIGHT) * item.filePrintIndizes.length),
              maxCellHeight
            );
          }
        }
      },
      didDrawCell: function(data) {
        if (data.column.dataKey === 'unit'){
          let hiddenImageCount = 0;

          let item = items[data.row.index];

          let maxCellY = doc.internal.pageSize.getHeight() - data.settings.margin.bottom; //Prevent images from being outside cells, if it already fills the whole page

          if (item && item.files && item.filePrintIndizes) {
            let files;
            if (Array.isArray(item.files)) { //Multiple files
              files = item.files;
            } else {
              files = [item.files];
            }

            for (let filePrintIndexRowIndex = 0; filePrintIndexRowIndex < item.filePrintIndizes.length; filePrintIndexRowIndex++){
              let currentX = doc.internal.pageSize.getWidth() - data.settings.margin.right;

              for (let filePrintIndex of item.filePrintIndizes[filePrintIndexRowIndex].indizes) {
                let file = files[filePrintIndex];
                let thumbnail;

                if (file.mime.includes('image')) {
                  thumbnail = file.blobURL;
                } else if (file.thumbnail) {
                  thumbnail = file.thumbnail;
                }

                let calculatedY = data.cell.y + lineHeight + data.cell.padding('top') + ((data.cell.padding('vertical') + IMAGE_HEIGHT) * filePrintIndexRowIndex);
                if (thumbnail) {
                  //If the image fits in the cell, add it
                  if ((calculatedY + IMAGE_HEIGHT + data.cell.padding('bottom')) <= maxCellY){
                    currentX -= data.cell.padding('right') + file.printSize.width;
                    doc.addImage(
                      thumbnail, 
                      currentX,
                      calculatedY, 
                      file.printSize.width, 
                      file.printSize.height, 
                      '', 'FAST');
                    currentX -= data.cell.padding('left');
                  } else { //Show an indicator of how many images are not being displayed
                    hiddenImageCount++;
                  }
                }
              }
            }
          }

          if (hiddenImageCount > 0){
            let text;
            if (hiddenImageCount === 1){
              text = `${hiddenImageCount} ${i18n.$t('report.view.image_hidden')}`;
            } else {
              text = `${hiddenImageCount} ${i18n.$t('report.view.images_hidden')}`;
            }
            let textDims = doc.getTextDimensions(text);
            
            doc.text(text, 
              (doc.internal.pageSize.getWidth() - data.settings.margin.right - textDims.w - data.cell.padding('right')), 
              data.cell.getTextPos().y + textDims.h
            );
          }
        }
      }
    });
    doc.setTextColor(currentTextColor);
    nextY = doc.lastAutoTable.finalY + 10;
  } else { // If no table was drawn, move down for the next text
    nextY = initialY + lineHeight;
  }

  return nextY;
}

export async function generateReportsPDF(doc, i18n, report, startingPage = 0) {
  doc.setFillColor(214, 241, 255);
  doc.rect(0, 0, doc.internal.pageSize.getWidth(), 30, 'F');
  doc.addImage('/assets/icon/favicon.png', 10, 5, 15, 15);
  doc.setFont('Oxygen');
  doc.setTextColor(80);
  doc.setDrawColor(80);
  doc.setFontSize(12);
  doc.text('anirec.de', 8.75, 26);
  doc.link(0, 0, 30, 30, {url: 'https://anirec.de'});
  doc.setFontSize(18);
  doc.text(`${report.title}`, 35, 13);
  doc.setFontSize(16);
  doc.setTextColor(100);
  let timeTextDimension = textAndDimension(doc, `${report.time}`, 35, 23);
  doc.setTextColor(80);
  if (report.control_examination === true) {
    doc.text(`| ${i18n.$t('report.control_examination')}`, 35 + timeTextDimension.w + 1, 23);
  }

  if (report.id != null) {
    let qrCodeOptions = {
      width: 300,
      height: 300,
      type: 'png',
      data: `https://app.anirec.de/health/analysis/report/${report.id}/`,
      image: '/assets/icon/favicon.png',
      margin: 10,
      qrOptions: {
        errorCorrectionLevel: 'Q'
      },
      dotsOptions: {
          color: '#9c59b6',
          type: 'square'
      },
      cornersSquareOptions: {
        color: '#3498db',
        type: 'square'
      },
      cornersDotOptions: {
        color: '#3498db',
        type: 'square'
      },
      imageOptions: {
          margin: 8
      }
    };

    //TODO Für QR Code eine GUID einführen?
    let qrCode = await new QRCodeStyling(qrCodeOptions).getRawData(); //FIXME QR Code Logo verschwindet, wenn sich ein Thumbnail von einer .MOV Datei im Bericht befindet! Nur auf iOS!

    let qrCodeImage = await qrCode.arrayBuffer();

    doc.addImage(new Uint8Array(qrCodeImage), qrCodeOptions.type.toUpperCase(), doc.internal.pageSize.getWidth() - 30 - MARGIN, 18, 30, 30);
    doc.link(doc.internal.pageSize.getWidth() - 30 - MARGIN, 18, 30, 30, {url: qrCodeOptions.data})
  }

  doc.setFontSize(12);
  
  let textSizes = [
    textAndDimension(doc, `${i18n.$t('report.associated_horse')}:`, FIRST_LINE_X, 38).w,
    textAndDimension(doc, `${i18n.$t('report.location.name')}:`, FIRST_LINE_X, 46).w
  ]

  let alignedX = Math.max(...textSizes) + FIRST_LINE_X + 5;

  doc.text(report.horse, alignedX, 38);
  doc.text(report.location, alignedX, 46);

  doc.setLineWidth(0.5);
  doc.setLineCap('round');
  doc.line(FIRST_LINE_X, 51, doc.internal.pageSize.getWidth() - MARGIN, 51);

  let nextY = 59;

  for (let category of report.fields) {
    nextY = displayCategory(doc, category, 'bold', 0, nextY, i18n);

    if (category.sub_categories){
      for (let subCategory of category.sub_categories){
        console.log(doc.getFontList())
        nextY = displayCategory(doc, subCategory, 'normal', 4, nextY, i18n);
      }
    }
  }

  doc.setFontSize(10);
  let pageCount = doc.internal.getNumberOfPages();
  for(let page = startingPage; page < pageCount; page++) { 
    doc.setPage(page); 
    let currentPage = doc.internal.getCurrentPageInfo().pageNumber;
    let text = `${report.horse} | ${report.title} | ${report.time} | ${i18n.$t('default_interaction.page')} ${currentPage} / ${pageCount}`;

    let textWidth = doc.getTextDimensions(text).w;
    doc.text(text, doc.internal.pageSize.getWidth() - textWidth - MARGIN, doc.internal.pageSize.getHeight() - 6);
  }
}

export default function PdfReport(i18n, report) {
  this.doc = new jsPDF({ putOnlyUsedFonts: true }); 
  this.startingPage = 0;
  this.reportMetadata = []; //TODO Save metadata for ToC

  this.attachReport = function(attachedReport){
    this.startingPage = this.doc.internal.getNumberOfPages();
    this.doc.addPage();
    this.reportMetadata.push(null); //TODO append metadata to array

    generateReportsPDF(this.doc, i18n, attachedReport, this.startingPage);
  }

  this.addTableOfContents = function() { //TODO Add Table of Contents with multiple reports

  }

  this.export = function(){
    return this.doc.output('blob');
  }

  generateReportsPDF(this.doc, i18n, report, this.startingPage);
}