<template>
  <ion-item v-if="value !== undefined || files !== undefined">
    <ion-label>
      <h2>{{ display_name }}</h2>
      <h3 v-if="displayValue !== undefined">{{ displayValue }} <span v-if="unit" class="unit">{{ unit }}</span> </h3>
      <ion-slides v-if="fileArray" :options="slideOpts" scrollbar>
        <ion-slide v-for="(media, mediaIndex) in mediaArray" :key="mediaIndex" @click="openGallery(modalComponent, mediaArray, mediaIndex)">
          <div class="media-thumbnail" v-if="media.thumbnail">
            <img class="media-thumbnail" :src="media.thumbnail" />
            <ion-icon class="play-overlay" v-if="media.type === 'video'" :icon="play" color="primary"></ion-icon>
          </div>
          <img class="media-thumbnail" v-else-if="media.type === 'image'" :src="media.blobURL" crossorigin="anonymous"/>
          <div class="media-thumbnail video-placeholder" v-else-if="media.type === 'video'">
            <ion-icon :icon="film" color="primary"></ion-icon>
          </div>
        </ion-slide>
      </ion-slides>
    </ion-label>
  </ion-item>
</template>



<script>

import { IonItem, IonLabel, IonSlides, IonSlide, IonIcon } from '@ionic/vue';
import { computed, defineComponent } from 'vue';

import { film, play } from 'ionicons/icons';

import { openGallery, default as modalComponent } from '@/components/Gallery.vue';

import { useDayjs } from '@/utils/dayjs';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  name: 'ViewReportEntryItem',
  components: { IonItem, IonLabel, IonSlides, IonSlide, IonIcon },
  props: {
    'display_name': String,
    'type': String,
    'unit': String,
    'value': {
      type: [String, Number, Boolean],
      default: undefined
    },
    'files': {
      type: [Array, Object],
      default: undefined
    },
  },
  setup(props) {
    const i18n = useI18n();

    const { dayjs, isReady, dayjsLocale, timezone } = useDayjs();

    const dateTimeInputTypes = ["datetime", "date", "time"];

    const componentType = computed(() => {
      switch (props.type) {
        //Handle special cases
        case 'date-time-val':
          return 'datetime';
        case 'date-val':
          return 'date';
        case 'time-val':
          return 'time';
        default:
          return props.type;
      }
    });

    const displayValue = computed(() => {
      if (props.value != null){
        //If we have a date type convert it
        let dateTimeObject;
        if (dateTimeInputTypes.includes(componentType.value)) {
          if (isReady.value) {
            dateTimeObject = dayjs.utc(props.value).locale(dayjsLocale.value).tz(timezone);
          } else {
            dateTimeObject = dayjs.utc(props.value).tz(timezone);
          }
        }

        //Format the value based on type
        switch (componentType.value) {
          case "datetime":
            return dateTimeObject.format('LLL');
          case "date":
            return dateTimeObject.format('LL');
          case "time":
            return dateTimeObject.format('LT');
          default:
            break;
        }
      }
      //Just return the raw value if nothing else catched it
      return props.value;
    });

    const slideOpts = computed(() => {
      return{
        updateOnWindowResize: true,
        resizeObserver: true,
        zoom: false,
        slidesPerView:(fileArray.value && fileArray.value.length > 1) ? 1.5 : 1,
        spaceBetween: 10,
        allowTouchMove: (fileArray.value && fileArray.value.length > 1)
      };
    });

    const fileArray = computed(() => {
      if (props.files) {
        if (Array.isArray(props.files)){
          return props.files;
        } else {
          return [props.files];
        }
      } else {
        return null;
      }
    });

    const mediaArray = computed(() => {
      let media = [];
      for (let file of fileArray.value) {
        media.push({
          type: props.type,
          mime: file.mime,
          thumbnail: file.thumbnail || undefined,
          blobURL: file.blobURL
        });
      }
      return media;
    });

    return { i18n, displayValue, slideOpts, fileArray, modalComponent, openGallery, mediaArray, film, play }
  }
});
</script>

<style scoped>
ion-slides {
  margin-top: 10px;
}

.video-placeholder {
  height: 150px;
  width: 250px;
  border: 3px solid var(--ion-color-primary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-placeholder ion-icon {
  font-size: 130px;
}

.media-thumbnail {
  max-height: 200px;
  cursor: pointer;
  position: relative;
}

.play-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  opacity: 0.9;
}

.unit {
  color: var(--ion-color-tertiary-tint);
  font-weight: bold;
}
</style>
