<template id="report-export-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('report.export.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-card v-if="reportsContainUpdatedOnes" color="warning" class="information-container">
        <ion-icon :icon="informationCircleOutline" size="large"></ion-icon>
        <div>{{ i18n.$t('report.export.no_link_hint_updated') }}</div>
      </ion-card>
      <ion-card :disabled="isLoadingReports">
        <ion-card-header>
          <ion-card-title>{{ i18n.$t('report.export.export_files') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="download-button-container">
          <ProgressButton class="file-button main-button" @click="downloadPDF()" color="success" :loading="isProcessingPDF" :disabled="isProcessingAnyFile">{{ i18n.$t('report.export.download_pdf') }}</ProgressButton>
          <ProgressButton class="file-button main-button" @click="downloadZIP()" color="success" :loading="isProcessingZIP" :disabled="isProcessingAnyFile" v-if="reportsWithFiles && reportsWithFiles.length > 0">{{ i18n.$t('report.export.download_zip') }}</ProgressButton>
        </ion-card-content>
      </ion-card>
      <div class="single-files-header" v-if="reportsWithFiles && reportsWithFiles.length > 0">
        <h3>{{ i18n.$t('report.export.single_files') }}</h3>
      </div>
      <ion-card :disabled="isLoadingReports" v-for="(report, index) in reportsWithFiles" :key="index">
        <ion-card-header>
          <ion-card-subtitle>{{ report.title }}</ion-card-subtitle>
          <ion-card-title>{{ report.horse }} | {{ report.time }}</ion-card-title>
        </ion-card-header>
        <ion-card-content class="download-button-container">
          <ProgressButton class="file-button" v-for="(fileObject, fileIndex) in report.files" :key="fileIndex"
            :loading="processingFiles[`${index}.${fileIndex}`]"
            :disabled="isProcessingAnyFile"
            @click="downloadFile(report, fileObject.filename, fileObject.extension, `${index}.${fileIndex}`)">
              {{ fileObject.filename }}{{ fileObject.extension }}
          </ProgressButton>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, modalController, getPlatforms } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';

import { informationCircleOutline } from 'ionicons/icons';

import ProgressButton from '@/components/ProgressButton.vue';

import { useDayjs } from '@/utils/dayjs';

import { useI18n } from "@/utils/i18n";

import PdfReport from "@/utils/pdf_report";

import JSZip from 'jszip';

import { download } from '@/utils/file_download';

const ReportExportModal = defineComponent({
  name: 'ReportExportModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, ProgressButton },
  props: {
    'report_indizes': Array,  //TODO When fetching multiple reports, just pass the indizes to fetch the corresponding report and add it in a loop with a progress bar to indicate how many reports are processed
    'report': Object
  },
  setup(props) {
    const i18n = useI18n();

    const { dayjs, dayjsLocale, timezone } = useDayjs();

    const pdf = ref(null);

    const zip = ref(new JSZip()); //Contains a folder for each report and there in all files as added below

    const reportsWithFiles = ref([]); //Array of objects with title, horse, time and files set - File data is stored in the zip above

    const isProcessingPDF = ref(true);

    const isProcessingZIP = ref(true);

    const isLoadingReports = ref(true);

    const processingFiles = ref({});

    const fileName = ref('');

    const isProcessingAnyFile = computed(() => {
      return (isProcessingPDF.value || isProcessingZIP.value || (Object.keys(processingFiles.value).length > 0));
    });

    const reportsContainUpdatedOnes = computed(() => {
      if (props.report) {
        if (props.report.id == null) return true;
      } else if (props.report_indizes) {
        for (let reportIndex of props.report_indizes) {
          if (reportIndex.startsWith && reportIndex.startsWith('U')) return true;
        }
      }
      return false;
    });

    const closeModal = function(){
      modalController.dismiss();
    }

    const getReportDescriptor = function(report){
      return `${report.horse} - ${report.time} - ${report.title}`
    }

    const downloadFile = function(report, filename, extension, processingId){
      processingFiles.value[processingId] = true;
      zip.value.folder(getReportDescriptor(report)).file(filename + extension).async('blob').then((blob) => {
        return download(i18n, getReportDescriptor(report) + ' - ' + filename + extension, blob, blob.type, getPlatforms())
        .catch(() => {
          return;
        });
      }).finally(() => delete processingFiles.value[processingId]);
    }

    const addFiles = function(report){ //FIXME PDF und ZIP erst erstellen, wenn angefordert, sonst hängt es bei großen Bildern und Videos ewig!
      let folder = zip.value.folder(getReportDescriptor(report));
      if (report.files) {
        let files = [];
        for (let file of report.files) {
          let multipleFilesIndex = 1;
          for (let fileObject of file.fileObjectArray){
            if (fileObject.blobURL != null) {
              let mimeFileType = (fileObject.mime != null) ? fileObject.mime.split('/').pop() : '';
              let mimeFileExtension = (mimeFileType.length) ? `.${mimeFileType}` : '.dat'; //Get the last part of the mime as a backup in case we have no extension!
              let tmpFile = {
                filename: file.keys.filter((key) => (key != 'uncategorized')).join(' - '), //Take all parts, except uncategorized, for the filename
                mime: fileObject.mime,
                extension: (fileObject.ext != null && fileObject.ext.length) ? fileObject.ext : mimeFileExtension
              };

              if (file.fileObjectArray.length > 1){
                tmpFile.filename += ` (${multipleFilesIndex})`;
                multipleFilesIndex++;
              }

              folder.file(tmpFile.filename + tmpFile.extension, fetch(fileObject.blobURL).then(fetchResult => fetchResult.blob()));
              
              files.push(tmpFile);
            }
          }

        }

        reportsWithFiles.value.push({
          id: report.id,
          title: report.title,
          horse: report.horse,
          time: report.time,
          files
        }); 
      }
    }

    const createPDFandZIP = async function(){
      if (props.report) {
        pdf.value = new PdfReport(i18n, props.report);
        addFiles(props.report);
        fileName.value = `${props.report.horse} - ${i18n.$t('report.export.default_filename_single_report')} ${props.report.time}`;
      } else if (props.report_indizes) {
        let now = dayjs.utc().locale(dayjsLocale.value).tz(timezone).format('LLL');
        fileName.value = `${i18n.$t('report.export.default_filename_multiple_reports')} ${now}`;
        for (let reportIndex of props.report_indizes) {
          let report = {id: reportIndex}; //TODO Fetch each report and localize it, fetch updated ones with id that starts with 'U' differently!
          if (pdf.value){
            pdf.value.attachReport(report);
          } else {
            pdf.value = new PdfReport(i18n, report);
          }
          addFiles(report);
        }
      }
    }

    const downloadPDF = function(){
      isProcessingPDF.value = true;
      let blob = pdf.value.export();
      download(i18n, `${fileName.value}.pdf`, blob, blob.type, getPlatforms())
      .catch(() => {
        return;
      }).finally(() => isProcessingPDF.value = false);
    }

    const downloadZIP = function(){
      isProcessingZIP.value = true;
      zip.value.generateAsync({type: 'blob', compression: 'DEFLATE'})
      .then(function (blob) {
        return download(i18n, `${fileName.value}.zip`, blob, blob.type, getPlatforms())
        .catch(() => {
          return;
        });
      }).finally(() => isProcessingZIP.value = false);
    }

    //TODO Add function to create CSV

    onMounted(() => {
      createPDFandZIP().then(() => {
        isProcessingPDF.value = false;
        isProcessingZIP.value = false;
        isLoadingReports.value = false;
      });
    });

    return { i18n, closeModal, reportsWithFiles, isProcessingPDF, isProcessingZIP, isLoadingReports, processingFiles, isProcessingAnyFile, reportsContainUpdatedOnes, downloadPDF, downloadZIP, downloadFile, zip, informationCircleOutline };
  }
});

export async function openReportExportModal(component, report_indizes){
  if (component != null && report_indizes != null && Array.isArray(report_indizes)) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          report_indizes
        },
      })
    return modal.present();
  }
}

export async function openReportExportModalSingleReport(component, report){
  if (component != null && report != null) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          report
        },
      })
    return modal.present();
  }
}

export default ReportExportModal;
</script>

<style scoped>
.file-button {
  text-transform: none;
  white-space: normal;
}

.main-button {
  font-weight: bold;
}

.download-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.single-files-header {
  width: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.single-files-header > h3 {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid var(--ion-color-medium);
}

.information-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-right: 10px;
}

.information-container > * {
  opacity: 1!important;
  padding: 10px 0px 10px 10px;
}

.information-container ion-icon {
  min-width: 24px;
  max-width: 24px;
}

</style>